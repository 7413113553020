// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// First load your regular JavaScript (copy all the JavaScript imports from your main pack).
// 1) use require or import for all 
// require("app/sites_manage")
// import "app/sites_manage"
// 2) use logic to incorporate all files in folder
let webpackContext = require.context('../app', true, /\.js(\.erb)?$/)
for(let key of webpackContext.keys()) { webpackContext(key) }


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
